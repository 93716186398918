import React, { useContext } from "react"
import { faCamera } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import { AppContext } from "../../../context/AppContext"
import guidelines from "./utils/uploadGuidelines.json"

const UploadGuidelinesModal = ({
  handleFileChooser,
  documentName = "Document",
}) => {
  const { dispatch } = useContext(AppContext)
  const handleCloseModal = () => {
    dispatch({ type: "HIDE_MODAL" })
    // dispatch({ type: "HIDE_SLIDING_MODAL" })
    handleFileChooser()
  }

  return (
    <div>
      <FontAwesomeIcon
        icon={faCamera}
        size="3x"
        className="has-text-primary is-white-in-dark"
      />
      <h3 className="mt-1 has-text-centered has-text-primary is-white-in-dark">
        Prescription Upload Guidelines
      </h3>
      <p className="content has-text-left">
        <ol>
          {guidelines.map((item, index) => (
            <li
              className="my-0 mr-1"
              key={index}
              dangerouslySetInnerHTML={{ __html: item ? item : null }}
            ></li>
          ))}
        </ol>
      </p>
      <div className="has-text-centered">
        <button
          className="button is-primary mt-1 is-next"
          onClick={handleCloseModal}
        >
          Upload {documentName}
        </button>
      </div>
    </div>
  )
}

export default UploadGuidelinesModal
